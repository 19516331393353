// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/lib/es6/src/Css.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Fetch from "bs-fetch/lib/es6/src/Fetch.js";
import * as React from "react";
import * as Js_exn from "bs-platform/lib/es6/js_exn.js";
import * as Js_dict from "bs-platform/lib/es6/js_dict.js";
import * as Belt_List from "bs-platform/lib/es6/belt_List.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Theme$GlazedWebsite from "../Theme.js";
import * as Layout$GlazedWebsite from "../Layout.js";
import * as Heading$GlazedWebsite from "../Heading.js";
import * as PageLayout$GlazedWebsite from "../PageLayout.js";
import * as ContactForm$GlazedWebsite from "../ContactForm.js";
import * as FormValidations$GlazedWebsite from "../shared/forms/FormValidations.js";

var fillFormMessage = Curry._1(Css.style, /* :: */[
      Css.display(/* inline */423610969),
      /* :: */[
        Css.paddingRight(Css.rem(0.3125)),
        /* :: */[
          Css.paddingBottom(Css.rem(1)),
          /* [] */0
        ]
      ]
    ]);

var sendEmailParagraph = Curry._1(Css.style, /* :: */[
      Css.display(/* inline */423610969),
      /* :: */[
        Css.color(Css.hex(Theme$GlazedWebsite.Colors.grey)),
        /* :: */[
          Css.marginBottom(Css.rem(2)),
          /* [] */0
        ]
      ]
    ]);

var link = Curry._1(Css.style, /* :: */[
      Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBlueDarkish)),
      /* [] */0
    ]);

var content = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(Css.rem(4.1875), Css.rem(1.25), Css.rem(8.1875), Css.rem(1.25)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.flexDirection(/* column */-963948842),
            /* :: */[
              Css.flex3(1, 0, /* auto */-1065951377),
              /* :: */[
                Css.backgroundColor(Css.hex(Theme$GlazedWebsite.Colors.almostAlmostWhite)),
                /* :: */[
                  Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                        Css.justifyContent(/* flexStart */662439529),
                        /* :: */[
                          Css.padding4(Css.rem(12.25), Css.rem(2.5), Css.rem(6.25), Css.rem(4.75)),
                          /* [] */0
                        ]
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var heading = Curry._1(Css.style, /* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.padding4(/* `rem */[
              5691738,
              1.875
            ], /* zero */-789508312, /* `rem */[
              5691738,
              5.625
            ], /* `rem */[
              5691738,
              0.9375
            ]),
        /* :: */[
          Css.fontSize(/* `rem */[
                5691738,
                4.125
              ]),
          /* :: */[
            Css.color(Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueText)),
            /* :: */[
              Css.before(/* :: */[
                    Css.contentRule(/* `text */[
                          -856044371,
                          ""
                        ]),
                    /* :: */[
                      Css.display(/* block */888960333),
                      /* :: */[
                        Css.position(/* absolute */-1013592457),
                        /* :: */[
                          Css.top(/* `px */[
                                25096,
                                0
                              ]),
                          /* :: */[
                            Css.left(/* `px */[
                                  25096,
                                  0
                                ]),
                            /* :: */[
                              Css.width(Css.px(130)),
                              /* :: */[
                                Css.height(Css.px(130)),
                                /* :: */[
                                  Css.border(Css.px(1), /* solid */12956715, Css.hex(Theme$GlazedWebsite.Colors.glazedBabyBlueDarker)),
                                  /* :: */[
                                    Css.opacity(0.8),
                                    /* :: */[
                                      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                                            Css.width(/* `px */[
                                                  25096,
                                                  258
                                                ]),
                                            /* :: */[
                                              Css.height(/* `px */[
                                                    25096,
                                                    258
                                                  ]),
                                              /* [] */0
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
                      Css.padding4(/* `rem */[
                            5691738,
                            5.625
                          ], /* zero */-789508312, /* `rem */[
                            5691738,
                            6.875
                          ], /* `rem */[
                            5691738,
                            0.9375
                          ]),
                      /* :: */[
                        Css.width(/* auto */-1065951377),
                        /* :: */[
                          Css.fontSize(Css.rem(9)),
                          /* :: */[
                            Css.before(/* :: */[
                                  Css.top(/* `px */[
                                        25096,
                                        -30
                                      ]),
                                  /* [] */0
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var pageContent = Curry._1(Css.style, /* :: */[
      Css.media(Theme$GlazedWebsite.Breakpoints.tabletLandscape, /* :: */[
            Css.maxWidth(Css.rem(50)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var Styles = {
  fillFormMessage: fillFormMessage,
  sendEmailParagraph: sendEmailParagraph,
  link: link,
  content: content,
  heading: heading,
  pageContent: pageContent
};

var emailSubject = "Glazed Application";

function Next(Props) {
  var match = React.useState((function () {
          return /* Pristine */0;
        }));
  var setSubmissionStatus = match[1];
  var submissionStatus = match[0];
  var onSubmit = React.useCallback((function (values) {
          Curry._1(setSubmissionStatus, (function (_status) {
                  return /* Pending */1;
                }));
          var valuesArray = Curry._1(FormValidations$GlazedWebsite.Values.toArray, values);
          var payload = Js_dict.fromList(Belt_List.map(Belt_List.add(Belt_List.fromArray(valuesArray), /* tuple */[
                        "subject",
                        emailSubject
                      ]), (function (param) {
                      return /* tuple */[
                              param[0],
                              param[1]
                            ];
                    })));
          fetch("/.netlify/functions/sendEmail", Fetch.RequestInit.make(/* Post */2, {
                              "Content-Type": "application/json"
                            }, Caml_option.some(JSON.stringify(payload)), undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(/* () */0)).then((function (response) {
                        if (response.status < 200 || response.status >= 300) {
                          return Promise.reject(Js_exn.raiseError(response.statusText));
                        } else {
                          return Promise.resolve(response);
                        }
                      })).then((function (prim) {
                      return prim.json();
                    })).then((function (_response) {
                    return Promise.resolve(Curry._1(setSubmissionStatus, (function (_status) {
                                      return /* Success */2;
                                    })));
                  })).catch((function (_error) {
                  return Promise.resolve(Curry._1(setSubmissionStatus, (function (_status) {
                                    return /* Failed */[/* Unexpected */1];
                                  })));
                }));
          return /* () */0;
        }), [setSubmissionStatus]);
  var onChange = React.useCallback((function (_values) {
          return (function (_eventMetadata) {
              if (typeof submissionStatus === "number") {
                return /* () */0;
              } else {
                return Curry._1(setSubmissionStatus, (function (_status) {
                              return /* Pristine */0;
                            }));
              }
            });
        }), /* tuple */[
        submissionStatus,
        setSubmissionStatus
      ]);
  return React.createElement(Layout$GlazedWebsite.make, {
              children: React.createElement(PageLayout$GlazedWebsite.make, {
                    children: null,
                    className: content,
                    useDarkNavBarLinks: true
                  }, React.createElement(Heading$GlazedWebsite.make, {
                        level: /* H1 */0,
                        className: heading,
                        children: "Next"
                      }), React.createElement("div", {
                        className: pageContent
                      }, React.createElement("p", {
                            className: fillFormMessage
                          }, "At Glazed we're committed to taking software development to next level. Many of our developers started by doing an internship or developing their thesis at Glazed, and you could be next! We will provide the support you need to help your project bloom."), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("p", {
                            className: fillFormMessage
                          }, "Send your application by filling the form below, or, if you prefer,"), React.createElement("p", {
                            className: sendEmailParagraph
                          }, "<a>", React.createElement("a", {
                                className: link,
                                href: "mailto:info@glazedsolutions.com"
                              }, "send us an email"), "</a>"), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement(ContactForm$GlazedWebsite.make, {
                            onSubmit: onSubmit,
                            onChange: onChange,
                            submissionStatus: submissionStatus,
                            hackyOverride: true
                          })))
            });
}

var make = Next;

var $$default = Next;

export {
  Styles ,
  emailSubject ,
  make ,
  $$default ,
  $$default as default,
  
}
/* fillFormMessage Not a pure module */
